import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import RichTextEditor from 'react-rte';
import parse from 'html-react-parser';
import {
  animationScrollToTop,
  convertHtmlToString,
  convertMessageToHtmlAndTrimSpaces,
  getSenderName,
  isJustNowMessageCheck,
  shortenFilename
} from '../utils';
import { ReactComponent as DownloadIcon } from 'assets/LoadCircle.svg';
import i18nContext from 'components/i18n-context';
import {
  CONFIRMATION_HANDLE_TYPES,
  MAX_UPLOADED_FILES_SIZE,
  SMS_ERROR_CODE_DISABLE
} from 'components/common/constants';
import Loader from 'components/common/Loader';
import { ConfirmCodeWrapper } from 'components/common/ConfirmCodeWrapper/ConfirmCodeWrapper';
import { isFullSecurityCode, parseDate } from 'components/utils';
import Button from 'uikit/Button/Button';
import { IconButton } from 'uikit/IconButton/IconButton';
import Input from 'uikit/Input/Input';
import { AttachDoc } from 'uikit/AttachDoc/AttachDoc';
import { getSecureUserPhone } from 'services/authUtils';
import { convertBytesToMegabytes, getErrorMessageForAlert, messageRegExp } from 'services/utils';
import '../Messages.scss';

const Chat = ({
  topic,
  messages,
  userAccount,
  isLoading,
  error,
  clearError,
  securityCode,
  setSecurityCode,
  generateSecurityCode,
  resendSecurityCode,
  setNewMessageData,
  createNewMessage,
  uploadedFiles,
  uploadDocuments,
  removeAttachment,
  getCurrentTopicMessagesFilesUrls,
  downloadAttachment,
  topicsRef,
  canWriteMessages,
  clearUploadedFiles,
  isNewMessageCreated,
  setIsNewMessageCreated
}) => {
  const i18n = useContext(i18nContext);
  const { id } = useParams();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isShowConfirmationCode, setIsShowConfirmationCode] = useState(false);
  const [alertState, setAlertState] = useState({ type: '', message: '' });
  const [showCountDown, setShowCountDown] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [hasScroll, setHasScroll] = useState(false);
  const chatRef = useRef(null);
  const [messageValue, setMessageValue] = useState(RichTextEditor.createEmptyValue());

  useEffect(() => {
    getCurrentTopicMessagesFilesUrls();

    if (chatRef) {
      const chat = chatRef.current;
      if (chat && chat.scrollHeight > chat.clientHeight) {
        setHasScroll(true);
      } else {
        setHasScroll(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const form = useFormik({
    validateOnChange: false,
    initialValues: {
      message: ''
    },
    validationSchema: Yup.object({
      message: Yup.string()
        .required(i18n.getMessage('messages.error.message.empty'))
        .max(2000, i18n.getMessage('messages.error.message.maxSize', { size: 2000 }))
        .matches(messageRegExp, i18n.getMessage('messages.error.message.invalidFormat'))
    }),
    onSubmit: () => {
      setNewMessageData(convertMessageToHtmlAndTrimSpaces(messageValue));
      generateSecurityCode().then(() => {
        setIsShowConfirmationCode(true);
        setShowCountDown(true);
        setAlertState({ type: 'success', message: i18n.getMessage('pin.alert.sent') });
      });
    }
  });

  const { values, errors, handleSubmit, validateField, submitCount, setFieldValue, resetForm } = form;

  useEffect(() => {
    if (id) {
      resetForm();
      setIsDisabled(false);
      setIsShowConfirmationCode(false);
      setMessageValue(RichTextEditor.createEmptyValue());
      setAlertState({ type: '', message: '' });
      setSecurityCode('');
      clearUploadedFiles();
    }
  }, [id]);

  useEffect(() => {
    setFieldValue('message', convertHtmlToString(messageValue));
  }, [messageValue, setFieldValue]);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  useEffect(() => {
    if (SMS_ERROR_CODE_DISABLE.includes(error?.code)) {
      setIsDisabled(true);
    }
    if (error) {
      setAlertState({ type: 'warning', message: getErrorMessageForAlert(i18n, error) });
    } else {
      setAlertState({ type: '', message: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleResendCode = async () => {
    clearError();
    setShowCountDown(true);
    await resendSecurityCode();
    isDisabled && setIsDisabled(false);
    setAlertState({ type: 'success', message: i18n.getMessage('pin.alert.sent') });
  };

  const onTimerEnd = () => {
    setShowCountDown(false);
  };

  const onComplete = (code) => {
    setSecurityCode(code);
    setAlertState({ type: '', message: '' });
  };

  const onEditMessage = () => {
    setIsShowConfirmationCode(false);
    setIsDisabled(false);
    setSecurityCode('');
    setAlertState({ type: '', message: '' });
  };

  const onConfirm = async () => {
    await createNewMessage(topic.id);
  };

  useEffect(() => {
    if (isNewMessageCreated) {
      animationScrollToTop(chatRef);
      animationScrollToTop(topicsRef);
      setHoveredIndex(null);
      setIsShowConfirmationCode(false);
      setAlertState({ type: '', message: '' });
      setShowCountDown(true);
      setIsDisabled(false);
      setFieldValue('message', '');
      setMessageValue(RichTextEditor.createEmptyValue());
      resetForm();
      setIsNewMessageCreated(false);
    }
  }, [isNewMessageCreated]);

  return (
    <div className='messages-right-side'>
      {isLoading ? (
        <div className='loader-container'>
          <Loader />
        </div>
      ) : (
        <>
          <div className='chat-subject-wrapper'>
            <span className='chat-subject-text'>{topic?.subject}</span>
          </div>
          <div id='chat-messages' className='chat-messages-wrapper' ref={chatRef}>
            {messages.map((message, index) => {
              const createdAt = parseDate(message.create_at);
              const isJustNowMessageCreated = isJustNowMessageCheck(message.create_at);

              return (
                <div key={index} className='chat-message-block'>
                  <div className='chat-message-head'>
                    <span className='chat-message-author'>{getSenderName(message?.sender_type, userAccount)}</span>
                    {isJustNowMessageCreated ? (
                      <div className='chat-message-date-block'>
                        <span className='chat-message-date-just-now'>{i18n.getMessage('messages.date.justNow')}</span>
                      </div>
                    ) : (
                      <div className='chat-message-date-block'>
                        <span className='chat-message-date-text'>{createdAt.date}</span>
                        <span className='chat-message-date-text'>{createdAt.time}</span>
                      </div>
                    )}
                  </div>
                  <div className='chat-message-content'>{parse(message.body)}</div>
                  {message.documents.length > 0 && (
                    <div className='chat-message-attachments-wrapper'>
                      <span className='chat-message-attachments-label'>Attachments ({message.documents.length})</span>
                      <div className='chat-message-attachments-preview-wrapper'>
                        {message.documents.map((file, index) => {
                          return (
                            <div
                              key={index}
                              className='chat-message-attachment-wrapper'
                              onMouseEnter={() => handleMouseEnter(index)}
                              onMouseLeave={handleMouseLeave}
                            >
                              {!file.url ? (
                                <div className='chat-message-attachment'>
                                  <p className='placeholder-wave h-100'>
                                    <span className='placeholder w-100 h-100 skeleton' />
                                  </p>
                                </div>
                              ) : file.content_type === 'application/pdf' ? (
                                <embed className='chat-message-attachment' src={file.url} type='application/pdf' />
                              ) : (
                                <img className='chat-message-attachment' src={file.url} alt={file.name} />
                              )}
                              {hoveredIndex === index && (
                                <div className='overlay'>
                                  <div className='overlay-top-half'>
                                    <span className='chat-message-attachment-name'>{shortenFilename(file.name)}</span>
                                  </div>
                                  <div className='overlay-bottom-half'>
                                    <div className='overlay-icon-wrapper'>
                                      <IconButton
                                        onClick={() => downloadAttachment(file.id)}
                                        Icon={DownloadIcon}
                                        isDisabled={isLoading}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          {canWriteMessages && (
            <form
              action='#'
              id='chat-form'
              onSubmit={handleSubmit}
              className={`chat-form ${hasScroll ? 'has-scroll' : ''}`}
            >
              <Input
                className='chat-form-input'
                type={'text-editor'}
                autoFocus={true}
                label={i18n.getMessage('messages.form.input.message.label')}
                name={'message'}
                isDisabled={isShowConfirmationCode}
                value={messageValue}
                onChange={setMessageValue}
                onBlur={() => validateField('message')}
                initialStatus={submitCount}
                max={2000}
                rows={4}
                error={errors?.message}
                /* eslint-disable-next-line max-len */
                subText={i18n.getMessage('messages.form.input.message.symbolsLeft', {
                  amount: 2000 - values.message.length
                })}
              />
              <AttachDoc
                label={i18n.getMessage('messages.form.attachDoc.label', { amount: uploadedFiles.length })}
                className='chat-form-attachments'
                type='file'
                size={MAX_UPLOADED_FILES_SIZE}
                isDisabled={isShowConfirmationCode}
                files={uploadedFiles}
                onChange={uploadDocuments}
                onRemoveFiles={removeAttachment}
                error={error?.type === 'attachDoc' && getErrorMessageForAlert(i18n, error)}
                hintText={i18n.getMessage('attachDoc.hint.text', {
                  maxFilesSize: convertBytesToMegabytes(MAX_UPLOADED_FILES_SIZE)
                })}
              />
              {isShowConfirmationCode ? (
                <>
                  <ConfirmCodeWrapper
                    PhoneComponent={<strong>{getSecureUserPhone()}</strong>}
                    isDisabled={isDisabled}
                    error={!error?.type && error}
                    onComplete={onComplete}
                    showCountDown={showCountDown}
                    handleResendCode={handleResendCode}
                    onTimerEnd={onTimerEnd}
                    alertState={alertState}
                    isMessenger={true}
                    confirmationHandleType={CONFIRMATION_HANDLE_TYPES.ON_CHANGE}
                  />
                  <div className={'actions-wrapper'}>
                    <Button className={'edit-message-button'} size='large' type='outline' onClick={onEditMessage}>
                      {i18n.getMessage('messages.button.editMessage')}
                    </Button>
                    <Button
                      className={'confirm-button'}
                      type={'primary'}
                      size={'large'}
                      fullWidth={true}
                      isDisabled={!isFullSecurityCode(securityCode)}
                      onClick={onConfirm}
                    >
                      {i18n.getMessage('messages.button.confirm')}
                    </Button>
                  </div>
                </>
              ) : (
                <Button
                  className='chat-form-button'
                  type={'primary'}
                  roleType={'submit'}
                  size={'large'}
                  fullWidth={true}
                  onClick={() => {}}
                  isDisabled={isLoading}
                >
                  {isLoading ? <Loader /> : i18n.getMessage('messages.button.sendMessage')}
                </Button>
              )}
            </form>
          )}
        </>
      )}
    </div>
  );
};

Chat.propTypes = {
  topic: PropTypes.object,
  messages: PropTypes.array.isRequired,
  userAccount: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.any,
  clearError: PropTypes.func.isRequired,
  securityCode: PropTypes.string.isRequired,
  setSecurityCode: PropTypes.func.isRequired,
  generateSecurityCode: PropTypes.func.isRequired,
  resendSecurityCode: PropTypes.func.isRequired,
  setNewMessageData: PropTypes.func.isRequired,
  createNewMessage: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array,
  uploadDocuments: PropTypes.func.isRequired,
  removeAttachment: PropTypes.func.isRequired,
  getCurrentTopicMessagesFilesUrls: PropTypes.func.isRequired,
  downloadAttachment: PropTypes.func.isRequired,
  topicsRef: PropTypes.any.isRequired,
  canWriteMessages: PropTypes.bool.isRequired,
  clearUploadedFiles: PropTypes.func.isRequired,
  isNewMessageCreated: PropTypes.bool.isRequired,
  setIsNewMessageCreated: PropTypes.func.isRequired
};

export default inject((stores) => ({
  messagesStore: stores.messagesStore
}))(observer(Chat));
